var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Brand report")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Filter report. Shows month by standard.")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xsOnly,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"overlap":"","color":"badges"},scopedSlots:_vm._u([(_vm.filtersCount > 0)?{key:"badge",fn:function(){return [_vm._v(_vm._s(_vm.filtersCount))]},proxy:true}:null],null,true)},[_c('v-btn',_vm._g({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.filtersTmp = _vm.cloneDeep(_vm.filters);
                _vm.showFiltersDialog = true;}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-filter")])],1)],1)]}}])},[_c('span',[_vm._v("Filter")])]),_c('v-btn',{staticClass:"hidden-sm-and-up",attrs:{"color":"primary","fixed":"","bottom":"","right":"","fab":""},on:{"click":function($event){_vm.filtersTmp = _vm.cloneDeep(_vm.filters);
          _vm.showFiltersDialog = true;}}},[_c('v-icon',[_vm._v("fas fa-filter")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"loading":_vm.loading,"items-per-page":10000},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"body",fn:function(ref){
          var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.business ? item.business.display_name : '-')+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.clicks)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.unique_clicks)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.sales)))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.conversion))+"% ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.value)))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.commission))+" ")])])}),0)]}}:null],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"}),_c('template',{slot:"items"},[_c('tr')])],2)],1)],1),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.showFiltersDialog),callback:function ($$v) {_vm.showFiltersDialog=$$v},expression:"showFiltersDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(" Filter report ")]),_c('v-card-text',[_c('date-picker',{attrs:{"label":"Start date","cancel-button":"","reset-button":""},model:{value:(_vm.filtersTmp.start_date),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "start_date", $$v)},expression:"filtersTmp.start_date"}}),_c('date-picker',{attrs:{"label":"End date","cancel-button":"","reset-button":""},model:{value:(_vm.filtersTmp.end_date),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "end_date", $$v)},expression:"filtersTmp.end_date"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.filters = _vm.cloneDeep(_vm.filtersTmp);
            _vm.showFiltersDialog = false;}}},[_vm._v(" Filtrér ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }