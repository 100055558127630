<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Brand report</div>
          <span class="subtitle-1 light-grey"
            >Filter report. Shows month by standard.</span
          >
        </div>
        <v-spacer></v-spacer>

        <!--Larger than mobile button-->
        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  showFiltersDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>

        <!--Mobile button-->
        <v-btn
          color="primary"
          class="hidden-sm-and-up"
          fixed
          bottom
          right
          fab
          @click="
            filtersTmp = cloneDeep(filters);
            showFiltersDialog = true;
          "
        >
          <v-icon>fas fa-filter</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :loading="loading"
          :items-per-page="10000"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <template v-if="$vuetify.breakpoint.smAndUp" v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  {{ item.business ? item.business.display_name : '-' }}
                </td>
                <td class="text-right">{{ item.clicks | local_numbers }}</td>
                <td class="text-right">{{ item.unique_clicks | local_numbers }}</td>
                <td class="text-right">{{ item.sales | local_numbers }}</td>
                <td class="text-right">
                  {{ item.conversion | local_numbers }}%
                </td>
                <td class="text-right">{{ item.value | local_numbers }}</td>
                <td class="text-right">
                  {{ item.commission | local_numbers }}
                </td>
              </tr>
            </tbody>
          </template>
          <template slot="items">
            <tr></tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showFiltersDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Start date"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="End date"
            cancel-button
            reset-button
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              showFiltersDialog = false;
            "
          >
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import DatePicker from "@/components/common/filters/DatePicker";
import { mapActions } from "vuex";

export default {
  components: { DatePicker },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    items: [],
    loading: false,
    showFiltersDialog: false,
    filtersTmp: {},
    filters: {}
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    },
    tableHeaders() {
      return [
        { text: "Brand", value: "business.display_name", sortable: true },
        { text: "Clicks", value: "clicks", sortable: true, align: "right" },
        { text: "Unique clicks", value: "unique_clicks", sortable: true, align: "right" },
        { text: "Sales", value: "sales", sortable: true, align: "right" },
        {
          text: "Conversion rate",
          value: "conversion",
          sortable: true,
          align: "right"
        },
        { text: "Revenue", value: "value", sortable: true, align: "right" },
        {
          text: "Commission",
          value: "commission",
          sortable: true,
          align: "right"
        }
      ];
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", { loadStats: "getStats" }),

    getStats() {
      this.loading = true;

      let params = this.cloneDeep(this.filters);
      params.group = ["business"];
      this.loadStats(params).then(
        stats => {
          this.loading = false;
          this.items = stats;
        },
        () => {
          this.setSnackError("Could not fetch data");
        }
      );
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();

    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      this.getStats();
    }
  }
};
</script>
